<template>
    <div id="accusation-detail">
        <a-page-header style="border-bottom: 2px solid rgb(235, 237, 240); margin-bottom: 10px;" title="详细信息" sub-title="" />
        <div class="blog-detail">
            <a-card :hoverable="true">
                <a-row>
                    <a-col span="3">
                        <a-statistic title="类别" :value="$store.state.category.category[category] ? $store.state.category.category[category] : '未分类'" style="margin-right: 50px" />
                    </a-col>
                    <a-col span="3">
                        <a-statistic title="状态" :value="statusMap[status]" style="margin-right: 50px" :valueStyle="statusStyle" />
                    </a-col>
                    <a-col span="6">
                        <a-statistic title="是否复议" :value="repeat == 0 ? '初议' : '复议'" style="margin-right: 50px">
                            <template #suffix v-if="repeat == 1">
                                <div style="font-size: large;"><a :href="'/filed-detail/' + RID" target="_blank">点此查看初议信息</a></div>
                            </template>
                        </a-statistic>
                    </a-col>
                    <a-col span="6">
                        <a-statistic title="电话" :value="anonymous == 0 ? phone : '匿名提交'" style="margin-right: 50px" groupSeparator="" />
                    </a-col>
                    <a-col span="6">
                        <a-statistic title="提交时间" :value="time" style="margin-right: 50px" />
                    </a-col>
                </a-row>
                <a-row style="margin-top: 5px;">
                    <a-col span="24">
                        <a-statistic title="地点" :value="location" style="margin-right: 50px" />
                    </a-col>
                </a-row>
            </a-card>
        </div>
        <a-row>
            <a-col span="16" style="padding-right: 5px; text-align: left;">
                <a-card title="内容" :hoverable="true">
                    <div style="min-height: 40vh; font-size: large; color: rgb(95, 95, 95);">
                        {{issue}}
                    </div>
                </a-card>
            </a-col>
            <a-col span="8" style="padding-left: 5px; text-align: left;">
                <a-card title="图片" :hoverable="true">
                    <div class="img-grid">
                        <div class="img-item" v-for="(img,index) in imgs" :key='img'>
                            <a-card class="square">
                                <div class="img-container center">
                                    <a-image class="min" :src="img" width="" />
                                </div>
                                <div class="download">
                                    <a :href="img" :download="location + '图片' + index">下载</a>
                                </div>
                            </a-card>
                        </div>
                    </div>
                </a-card>
            </a-col>
        </a-row>
        <a-divider />
        <a-row style="margin-top: 24px; text-align: left;">
            <a-col span="24" style="padding-left: 5px; text-align: left;">
                <a-card title="历史反馈" :hoverable="true">
                    <div style="font-size: large;">
                        <a-timeline>
                            <a-timeline-item v-for="(feedback, index) in feedbacks" :key="feedback.title + ' - ' + feedback.time">
                                <div class="feedback-time">{{feedback.time}}</div>
                                <div class="feedback-title">{{feedback.title}}</div>
                                <div class="feedback-content">{{feedback.content}}</div>
                            </a-timeline-item>
                            <a-timeline-item>
                                <div class="feedback-time">{{time}}</div>
                                <div class="feedback-title">用户提交</div>
                                <div class="feedback-content">用户提交</div>
                            </a-timeline-item>
                        </a-timeline>
                        <!-- <a-collapse>
                            <a-collapse-panel v-for="feedback in feedbacks" :key="feedback.title" :header="feedback.title + ' - ' + feedback.time">
                                <p>{{ feedback.content }}</p>
                            </a-collapse-panel>
                        </a-collapse> -->
                    </div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
    methods: { },
    data () {
        return { };
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const ID = ref(route.params.id);

        const UID = ref('');
        const anonymous = ref(0);
        const category = ref(1);
        const type = ref(0);
        const time = ref(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        const location = ref('河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店');
        const issue = ref('河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了河北省保定市莲池区恒祥北大街丽景蓝湾门口XXXX便利店咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了咋了');
        const longitude = ref(0);
        const latitude = ref(0);
        const ip = ref('');
        const imgs = ref([
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg',
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg',
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg',
            'https://s3.bmp.ovh/imgs/2022/08/09/795de76021e11aea.jpeg'
            ]);
        const status = ref(100);
        const repeat = ref(1);
        const RID = ref('');
        const phone = ref('13333111133');
        const feedbacks = ref([
            {
                time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                title: '反馈1',
                content: '反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容反馈1内容'
            },
            {
                time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                title: '反馈2',
                content: '反馈2内容'
            },
            {
                time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                title: '反馈3',
                content: '反馈3内容'
            }
        ]);
        const statusStyle = computed(() => {
            if (status.value == 0) {
                return {color: '#fe4d55'};
            } else if (status.value == 1) {
                return {color: '#fdb927'};
            } else if (status.value == 100) {
                return {color: '#ffdb10'};
            } else if (status.value == 101) {
                return {color: 'rgb(0, 204, 253)'};
            } else if (status.value == 102) {
                return {color: '#4cdc71'};
            } else {
                return {color: 'black'};
            }
        });

        const categoryMap = ref(['', '食品', '药品', '计量', '电梯', '虚假广告']);
        const statusMap = ref({
            0: '不受理',
            1: '用户撤回',
            100: '已提交',
            101: '已受理',
            102: '已完成'
        });
        const statusOptions = ref([
            {
                value: 0,
                label: '不受理'
            },
            {
                value: 100,
                label: '已提交'
            },
            {
                value: 101,
                label: '已受理'
            },
            {
                value: 102,
                label: '已完成'
            }
        ]);

        onMounted(() => {
            store.dispatch({ type: 'setSelectedKeys', selectedKeys: ['Filed'] });
            store.dispatch({ type: 'setOpenKeys', openKeys: ['Filed'] });
        });

        return {
            ID,
            UID,
            anonymous,
            category,
            type,
            time,
            location,
            issue,
            longitude,
            latitude,
            ip,
            imgs,
            status,
            repeat,
            RID,
            phone,
            feedbacks,
            statusStyle,
            categoryMap,
            statusMap,
            statusOptions
        }
    }
}
</script>

<style>
#accusation-detail {
    width: 100%;
    text-align: center;
}

.rows {
    margin-bottom: 10px;
}

.blog-detail {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

:root {
    --img-size: 0
}

.img-grid {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.img-item {
    display: flex;
    justify-content: center;
    width: 30%;
    margin: 1% 1%;
    /* height: 20vh; */
    /* height: 0; */
    /* padding-bottom: 23%; */
    overflow: hidden;
    border-radius: 10px;
}

.square {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.min {
    width: 100%;
    /* height: 10vh; */
}

.img-container {
    /* padding: 10px; */
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    overflow: hidden;
}

.download {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 5px;
    left: 0;;
}

.feedback-time {
    font-size: larger;
    margin-bottom: 10px;
}

.feedback-title {
    font-size: x-large;
    margin-bottom: 10px;
    border-left: 3px solid rgb(235, 237, 240);
    padding-left: 10px;;
}

.feedback-content {
    font-size: large;
    color: rgb(95, 95, 95);
    padding-left: 15px;
    /* border-bottom: 1px solid rgb(235, 237, 240); */
}
</style>
